import React, { Component } from "react";
import axios from "axios";
import InfoBoxes from "./InfoBoxes";
import TopNav from "./TopNav";
import Hero from "./Hero";

export class Content extends Component {
  state = {
    infoboxes: [],
    pages: [],
    skills: [],
    isLoaded: false,
  };
  async FetchData() {
    await axios
      .get(process.env.REACT_APP_WPURL + "infoboxes/?per_page=100")
      .then((res) =>
        this.setState({
          infoboxes: res.data,
        })
      )
      .catch((err) => console.log(err));
    await axios
      .get(process.env.REACT_APP_WPURL + "pages")
      .then((res) =>
        this.setState({
          pages: res.data,
        })
      )
      .catch((err) => console.log(err));
    await axios
      .get(process.env.REACT_APP_WPURL + "skills")
      .then((res) =>
        this.setState({
          skills: res.data,
          isLoaded: true,
        })
      )
      .catch((err) => console.log(err));
  }
  componentDidMount() {
    this.FetchData();
  }
  render() {
    const { infoboxes, pages } = this.state;
    // if(!this.state.isLoaded){return null}
    //console.log("pages",pages);
    return (
      <div className="content">
        <InfoBoxes category={"Shady_Header"} boxes={infoboxes} />

        <TopNav infoboxes={infoboxes} />

        <section
          id="section-featurettes"
          className="featurettes overlay bg-fixed"
          data-stellar-background-ratio="0.8"
        >
          <Hero slug={"safety-meta-world"} pages={this.state.pages} />
          <InfoBoxes category={"Safety_Meta_World"} boxes={infoboxes} />
        </section>
        <section
          id="features-1"
          className="bg-white boon-gl level-1"
          style={{ height: "800px" }}
        >
          <InfoBoxes category={"Infinitely_Destructive"} boxes={infoboxes} />
        </section>
        <section
          id="section-parallel"
          className="featurettes overlay bg-fixed"
          data-stellar-background-ratio="0.8"
        >
          <Hero slug={"parallel-card-game"} pages={this.state.pages} />
        </section>
      </div>
    );
  }
}

export default Content;
