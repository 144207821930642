import { useEffect, useState } from "react";
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState([
    Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
    Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    ),
  ]);

  useEffect(() => {
    const handleResize = () => {
      let vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      let vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
      //   console.log("handleSize", [vw, vh]);
      setWindowSize([vw, vh]);
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("onload", handleResize);
    return () => {
      window.removeEventListener("onload", handleResize);
      window.removeEventListener("resize", handleResize);
    };
  });
  return windowSize;
};
export default useWindowSize;
